import React from 'react';

const ExercisesProgressBar = ({ className, completedProgress }) => {
    return <div className={className}>
        <div className="progressBar"></div>
        <div className="rest"></div>
    </div>

};

export default ExercisesProgressBar;
