import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectors as playerSelectors, hooks as playerHooks } from 'state/player';

import { Exercise, ExercisesProgressBar, MediaAsset, Header, Loader } from './styled';

const Player = ({ className }) => {
  const currentExerciseId = useSelector(playerSelectors.getCurrentExerciseId);
  const completedProgressPercentage = useSelector(playerSelectors.getCompletedProgressPercentage);
  const { exercise, getExercise } = playerHooks.useGetExerciseById(currentExerciseId);

  useEffect(() => {
    if (!!currentExerciseId && !exercise) {
      getExercise();
    };
  }, [exercise, getExercise, currentExerciseId]);

  return <>
    <ExercisesProgressBar completedProgress={completedProgressPercentage}/>
    <div className={className}>
      <Header />
      {exercise
        ? <>
          <MediaAsset exercise={exercise} />
          <Exercise exercise={exercise} key={exercise.id}/>
        </>
        : <Loader />
      }
    </div>
  </>
};

export default Player;
