import React from 'react';

const ChooseTheAnswer = ({ className, exercise, setAnswer }) => {
  const answerOptions = exercise.answers_options;

  const handleSelection = (e) => {
    const answer = answerOptions[e.target.value];
    setAnswer([answer]);
  };

  return <div className={className}>
    {answerOptions.map((option, index) =>
      <label htmlFor={`${index}-${option}`} key={`${index}-${option}`}>
        <input id={`${index}-${option}`} type="radio" name="answer" value={index} onChange={handleSelection} />
        <span>{option}</span>
      </label>
    )}
  </div>;
};

export default ChooseTheAnswer;
