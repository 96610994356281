import React from "react";

import mediaAssets from "./mediaAssets";

const getAssetComponentByType = (assetType) => {
  const ASSET_BY_TYPE = {
    CLIP: mediaAssets.Video,
  };

  return ASSET_BY_TYPE[assetType] || null;
};

const MediaAsset = ({ className, exercise }) => {
  const { assets } = exercise;
  const [{ asset }] = assets;

  const assetType = asset.asset_type.human_id;
  const AssetComponentClass = getAssetComponentByType(assetType);

  return (
    <div className={className}>
      <AssetComponentClass asset={asset} />
    </div>
  );
};

export default MediaAsset;