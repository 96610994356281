import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as playerActions } from 'state/player';

const ExerciseFeedback = ({ className, success, exercise, onNextExercise }) => {
    const {
        modal_correct_title: correctTitle,
        modal_fail_title: wrongTitle,
        modal_correct_info: correctInfo,
        modal_fail_info: wrongInfo,
    }  = exercise;

    const dispatch = useDispatch();
    let history = useHistory();

    const onContinue = useCallback(() => {
        onNextExercise();
        dispatch(playerActions.goToNextExercise({
            requeueCurrentExercise: !success && !exercise.retried,
            history
        }));
    }
        , [dispatch, success, exercise.retried, history, onNextExercise]);

    return success !== null && <div className={className}>
        <p className="title">
            {success
                ? (correctTitle || 'Correct!')
                : (wrongTitle || 'Incorrect answer')
            }
        </p>
        <p className="info">
            {success
                ? correctInfo
                : (
                    wrongInfo ||
                    exercise.retried
                        ? `The correct answer is: ${exercise.answer.join(' ')}`
                        : "Don't worry, you can try again at the end of this stage. Keep playing for a chance to retry!")
            }
        </p>

        <button className="continueButton" onClick={onContinue}>Continue</button>
    </div>;
};

export default ExerciseFeedback;
