
const CLIENT_NAME = 'client_name';
const USER_ID = 'user_id';
const EXERCISES_QUERY = 'exercises_query';
const BACK_URL = 'back_url';
const THEME = 'theme';
const WEBHOOKS = 'webhooks';

const decrypt = (sessionToken) => {
  const base64Url = sessionToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const generateUsername = ({ username }) => `${username}@wannalisnastex.com`;

export const extractDataFromSessionToken = (sessionToken) => {
  const decryptedToken = decrypt(sessionToken);

  return {
    [CLIENT_NAME] : decryptedToken[CLIENT_NAME],
    [USER_ID] : generateUsername({ username: decryptedToken[USER_ID] }),
    [EXERCISES_QUERY] : decryptedToken[EXERCISES_QUERY],
    [BACK_URL] : decryptedToken[BACK_URL],
    [THEME] : decryptedToken[THEME],
    [WEBHOOKS] : decryptedToken[WEBHOOKS],
  };
};
