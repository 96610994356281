import { takeLatest, put, select, call } from "@redux-saga/core/effects";
import { commonUtils, exerciseUtils } from 'utils';
import playerActions from './actionCreators';
import playerActionTypes from './actionTypes';
import selectors from './selectors';
import { exercisesApi as exercisesQueries } from './api';
import api from 'api';

function* handleGetExercise({ payload: { exerciseId } }) {
  try {
    const exercise = yield select(selectors.getExerciseById(exerciseId));

    if (!exercise) {
      yield put(playerActions.setLoading({ loading: true }));
      const exerciseResponse = yield call(exercisesQueries.getExerciseById, { exerciseId });
      yield put(playerActions.addExercise({exercise: exerciseResponse }));
    };
  } catch (error) {
    yield console.error(error);
  } finally {
    yield put(playerActions.setLoading({ loading: false }));
  };
};

function* handleCreateExerciseLog({ payload: { exercise, answer, exerciseStartTime }}) {
  const isTheRightAnswer = exerciseUtils.validateAnswerForExercise({
    answer,
    exerciseAnswer: exercise.answer,
  });
  // const isTheRightAnswer = answer.join() === exercise.answer.join();
  const timeSpentInMinutes = Math.ceil((new Date() - exerciseStartTime) / (60 * 1000));
  const blockId = yield select(selectors.getBlockId);

  const getExecutionType = ({ exercise, isTheRightAnswer }) => {
    if (isTheRightAnswer) {
      return exercise.retried ? 'SECOND_TRY' : 'FIRST';
    };

    return 'FAILED';
  };

  const logContent = {
    exerciseId: exercise.id,
    categoryId: exercise.category,
    subcategoryId: 'Deprecated',
    subcategoryGroupId: 'Deprecated',
    score: 0, // It doesn't matter here
    clues: 0, // We give no clues,
    errors: 0, // It doesn't matter here
    passed: isTheRightAnswer,
    timeSpentMin: timeSpentInMinutes,
    sumPoints: true,
    type: 'PROVIDER_EXERCISE',
    userAnswer: answer,
    blockId,
    executionType: getExecutionType({ exercise, isTheRightAnswer }),
    language: exercise.language
  };

  try {
    yield call(api().post, { path: 'logs', body: logContent });
  } catch(error) {
    console.error(error);
  }
};

function* handleGoToNextExercise({ payload: { requeueCurrentExercise, history } }) {
  yield put(playerActions.advanceExercisesQueue({ requeueCurrentExercise }));
  const pendingExercisesIds = yield select(selectors.getExercisesIds);

  if (commonUtils.isEmpty(pendingExercisesIds)) {
    const queryString = window.location.search;
    history.push(`/feedback${queryString}`);
  };
};

export default function* saga() {
  yield takeLatest(playerActionTypes.GET_EXERCISE$, handleGetExercise);
  yield takeLatest(playerActionTypes.CREATE_EXERCISE_LOG$, handleCreateExerciseLog);
  yield takeLatest(playerActionTypes.GO_TO_NEXT_EXERCISE$, handleGoToNextExercise);
};
