const SET_LOADING = 'player/SET_LOADING';
const SET_EXERCISES_QUERY = 'player/SET_EXERCISES_QUERY';
const ADD_EXERCISES_TO_QUEUE = 'player/ADD_EXERCISES_TO_QUEUE';
const GO_TO_NEXT_EXERCISE$ = 'player/GO_TO_NEXT_EXERCISE$'
const ADVANCE_EXERCISES_QUEUE = 'player/ADVANCE_EXERCISES_QUEUE';
const GET_EXERCISE$ = 'player/GET_EXERCISE$';
const CLEAR_EXERCISES_QUEUE = 'player/CLEAR_EXERCISES_QUEUE';
const ADD_EXERCISE = 'player/ADD_EXERCISE';
const SET_BLOCK_ID = 'player/SET_BLOCK_ID';
const CREATE_EXERCISE_LOG$ = 'player/CREATE_EXERCISE_LOG$';

const playerActionTypes = {
  SET_LOADING,
  SET_EXERCISES_QUERY,
  ADD_EXERCISES_TO_QUEUE,
  GO_TO_NEXT_EXERCISE$,
  ADVANCE_EXERCISES_QUEUE,
  GET_EXERCISE$,
  ADD_EXERCISE,
  CLEAR_EXERCISES_QUEUE,
  SET_BLOCK_ID,
  CREATE_EXERCISE_LOG$,
};

export default playerActionTypes;
