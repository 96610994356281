const getStateData = (state) => state?.session?.data;

const getClient = (state) => getStateData(state)?.client;
const getTheme = (state) => getStateData(state)?.theme;
const getBackUrl = (state) => getStateData(state)?.back_url;
const getWebhooks = (state) => getStateData(state)?.webhooks;

const sessionSelectors =  {
  getClient,
  getTheme,
  getBackUrl,
  getWebhooks,
};

export default sessionSelectors;
