import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { actions as playerActions } from "state/player";

import exercises from "./exercises";
import { commonUtils, exerciseUtils } from "utils";
import { ExerciseFeedback } from "./styled";

const getExerciseComponentByType = (exerciseType) => {
  const EXERCISE_BY_TYPE = {
    SELECT_MEANING: exercises.ChooseTheAnswer,
    DICTATION: exercises.FillTheGaps,
    BANK_WORDS: exercises.WordsBank,
  };

  return EXERCISE_BY_TYPE[exerciseType] || null;
};

const Exercise = ({ className, exercise }) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState([]);
  const [success, setSuccess] = useState(null);
  const [exerciseStartTime] = useState(new Date());
  const { t } = useTranslation("Exercises");

  const cleanAndSetAnswer = (answer) =>
    setAnswer(
      answer.map((answerElement) => answerElement.trim().toLowerCase())
    );

  const exerciseType = exercise.type;
  const ExerciseComponentClass = getExerciseComponentByType(exerciseType);

  const validateAnswer = useCallback(() => {
    const exerciseAnswer = exercise.answer;
    const isTheRightAnswer = exerciseUtils.validateAnswerForExercise({
      answer,
      exerciseAnswer,
    });
    setSuccess(isTheRightAnswer);
    dispatch(
      playerActions.createExerciseLog({ exercise, answer, exerciseStartTime })
    );
    setAnswer([]);
  }, [exercise, answer, exerciseStartTime, dispatch, setAnswer, setSuccess]);

  const onNextExercise = useCallback(() => {
    setSuccess(null);
    setAnswer([]);
  }, [setAnswer, setSuccess]);

  return (
    <div className={className}>
      <p className="subtitle">
        {exercise.retried && (
          <span className="retrySubtitle">{t("retry")}</span>
        )}
        {exercise.subtitle}
      </p>
      <p className="title">{exercise.title}</p>
      <ExerciseComponentClass
        key={exercise.id}
        exercise={exercise}
        setAnswer={cleanAndSetAnswer}
      />
      {null === success && (
        <button onClick={validateAnswer} disabled={commonUtils.isEmpty(answer)}>
          Check
        </button>
      )}
      <ExerciseFeedback
        exercise={exercise}
        success={success}
        onNextExercise={onNextExercise}
      />
    </div>
  );
};

export default Exercise;
