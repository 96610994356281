const themes = {
    santander: {
        button: {
            colors: {
                primary: '#1BB3BC',
                primaryHover: '#137E84',
                secondary: '#FFFFFF',
                secondaryHover: '#F0F7F9',
            },
            animation: {
                hover: {
                    transition: '0.2s ease-in'
                }
            },
            state: {
                disabled: {
                    opacity: '0.3',
                }
            }
        },
        text: {
            colors: {
                primary: '#6F7779',
                secondary: '#FFFFFF',
                terciary: '#910041',
            }
        },
        exercises: {
            colors: {
                primary: '#DEEDF2',
                primaryHover: '#C3DEE7',
                secondary: '#000000',
            }
        },
        feedback: {
            colors: {
                title: '#000000',
                success: '#63BA68',
                fail: '#FFCC33',
                background: '#FF0000',
            }
        },
        progressBar: {
            colors: {
                primary: '#63BA68',
                secondary: '#FFCC33',
            }
        }
    },
    bbva: {
        button: {
            colors: {
                primary: '#2DCCCD',
                primaryHover: '#02A5A5',
                secondary: '#FFFFFF',
                secondaryHover: '#F4F4F4',
            },
            animation: {
                hover: {
                    transition: '0.2s ease-in'
                }
            },
            state: {
                disabled: {
                    opacity: '0.3',
                }
            }
        },
        text: {
            colors: {
                primary: '#121212',
                secondary: '#FFFFFF',
                terciary: '#2DCCCD',
            }
        },
        exercises: {
            colors: {
                primary: '#E9E9E9',
                primaryHover: '#D3D3D3',
            }
        },
        feedback: {
            colors: {
                success: '#48AE64',
                fail: '#F8CD51',
                background: '#072146',
            }
        },
        progressBar: {
            colors: {
                primary: '#48AE64',
                secondary: '#F8CD51',
            }
        }
    }
};

export default themes;
