import { createActions } from 'redux-actions';

import types from './actionTypes';

const { auth } = createActions({
  [types.SET_TOKEN]: ({ token }) => ({ token }),
  [types.SET_LOGGED_IN]: ({ loggedIn }) => ({ loggedIn }),
  [types.SET_USER]: ({ user }) => ({ user }),
});

export default auth;
