import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from "react-router-dom";

import { selectors as authSelectors } from 'state/auth';
import { ForbiddenPage } from 'pages';

const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(authSelectors.getIsLoggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn
          ? children
          : <ForbiddenPage />
      }
    />
  );
}

export default PrivateRoute;
