import React from 'react';

const Loader = ({ className }) =>
  <div className={className}>
    <div className="circle1 circle"></div>
    <div className="circle2 circle"></div>
    <div className="circle3 circle"></div>
    <div className="circle4 circle"></div>
    <div className="circle5 circle"></div>
    <div className="circle6 circle"></div>
    <div className="circle7 circle"></div>
    <div className="circle8 circle"></div>
    <div className="circle9 circle"></div>
  </div>;

export default Loader;
