const defaultTheme = {
    button:{
        colors: {
            primary: '#FF3D61',
            primaryHover: '#E53657',
            secondary: '#FFFFFF',
            secondaryHover: '#F2F2F2',
            terciary: '#E6E8ED',
        },
        animation: {
            hover: {
                transition: '0.2s ease-in'
            }
        },
        state: {
            disabled: {
                opacity: '0.3',
            }
        }
    },
    text: {
        colors: {
            primary: '#353E59',
            secondary: '#FFFFFF',
            terciary: '#5E698A',
        }
    },
    exercises: {
        colors: {
            primary: '#EDF2FF',
            primaryHover: '#D7E1FF',
            secondary: '#3B6CFF',
            underline: '#353E59',
        }
    },
    feedback: {
        colors: {
            title: '#6F7779',
            success: '#30D99E',
            fail: '#EEBB13',
            background: '#3451A8'
        }
    },
    progressBar: {
        colors: {
            primary: '#FF3D61',
            secondary: '#FFB600',
            terciary: '#E6E8ED',
        }
    }
}

export default defaultTheme;
