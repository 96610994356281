const SET_CLIENT = 'session/SET_CLIENT';
const SET_THEME = 'session/SET_THEME';
const SET_BACK_URL = 'session/SET_BACK_URL';
const SET_WEBHOOKS = 'session/SET_WEBHOOKS';

const sessionActionTypes = {
  SET_CLIENT,
  SET_THEME,
  SET_BACK_URL,
  SET_WEBHOOKS,
};

export default sessionActionTypes;
