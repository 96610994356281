const CONTENT_TYPES = {
    json: 'application/json',
};

const sendStats = async ({ stats, url }) => await fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(stats),
    headers: {
        'Content-Type': CONTENT_TYPES.json,
    }
});

export default sendStats;
