import React from 'react';

import {ReactComponent as Logo} from 'logo.svg';

const Header = ({ className }) =>
    <div className={className}>
        <p>Powered by</p>
        <Logo />
    </div>

export default Header;
