import React from 'react';

import { ReactComponent as ForbiddenImage } from './403.svg';

const ForbiddenPage = ({ className }) =>
  <div className={className}>
    <ForbiddenImage />
    <div className="content">
        <h1>403</h1>
        <h1>Forbidden</h1>
        <h2>You don't have permissions</h2>
        <button>Go back</button>
    </div>
  </div>

export default ForbiddenPage;
