import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleWare = createSagaMiddleware();

const middlewares = [sagaMiddleWare];

// persist config
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  blacklist: ['ui']
};

const getStore = () => {
  const persistedReducer = persistReducer(persistConfig, reducers);

  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )

  sagaMiddleWare.run(sagas);

  const persistor = persistStore(store);

  return { store, persistor };
}

export default getStore;
