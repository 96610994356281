const initialState = {
  data: {
    client: null,
    theme: null,
    back_url: null,
    webhooks: {},
  },
}

export default initialState;
