import { combineReducers } from 'redux';

import getStore from './store';

import ui from './ui/reducers';
import session from './session/reducers';
import player from './player/reducers';
import auth from './auth/reducers';

const appReducers = combineReducers({
  ui,
  session,
  player,
  auth,
});

const combinedReducers = (state, action) => appReducers(state, action);

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    getStore().persistor.purge();
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
}

export default rootReducer;
