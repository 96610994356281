import { handleActions } from 'redux-actions';

import actions from '../actionCreators';
import initialState from '../initial.state';

const data = handleActions(
    {
        [actions.setToken]: (state, { payload: { token } }) => ({
            ...state,
            token,
        }),
        [actions.setUser]: (state, { payload: { user } }) => ({
            ...state,
            user,
        }),
        [actions.setLoggedIn]: (state, { payload: { loggedIn } }) => ({
            ...state,
            loggedIn,
        }),
    },
    initialState.data || {},
);

export default data;
