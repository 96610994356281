const normalizeText = (text) =>
  text
    .toString()
    .trim()
    .toLowerCase()
    //Special Characteres
    .replace(/['‘`’´?¿¡!]/g, "")
    .normalize("NFD")
    // Accents
    .replace(/[\u0300-\u036f]/g, "");

const cleanAnswers = (answers) =>
  Array.isArray(answers) ? answers.map(normalizeText) : normalizeText(answers);

const validateAnswerForExercise = ({ answer, exerciseAnswer }) => {
  const userAnswer = cleanAnswers(answer).toString();
  const correctAnswer = cleanAnswers(exerciseAnswer).toString();
  return userAnswer === correctAnswer;
};

const exerciseUtils = {
  validateAnswerForExercise,
};

export default exerciseUtils;
