import styled from 'styled-components';

import ChooseTheAnswer from './ChooseTheAnswer';
import FillTheGaps from './FillTheGaps';
import WordsBank from './WordsBank';

const StyledChooseTheAnswer = styled(ChooseTheAnswer)`
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #FFFFFF;
      width: 1.3em;
      height: 1.3em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em ${props => props.theme.exercises.colors.secondary};
    }
  }

  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: mix(#FFFFFF, ${props => props.theme.exercises.colors.secondary}, 84%);
      &:before {
        box-shadow: inset 0 0 0 0.4375em ${props => props.theme.exercises.colors.secondary};
      }
    }
  }

  label {
    background: ${props => props.theme.exercises.colors.primary};
    border-radius: 6px;
    padding: 8px;
    cursor: pointer;
    margin-top: 10px;
    display: block;
    &:hover {
      background: ${props => props.theme.exercises.colors.primaryHover};
    }
  }
`;

const StyledFillTheGaps = styled(FillTheGaps)`
  display: flex;
  flex-direction: row;
  max-width: 720px;
  align-items: center;
  flex-wrap: wrap;

  .wrapper {
    background: ${props => props.theme.exercises.colors.primary};
    border-radius: 6px;
    border: none;
    padding: 8px;
    margin: 6px;
  }

  input {
    background: ${props => props.theme.exercises.colors.primary};
    border: none;
    border-bottom: 1px solid ${props => props.theme.exercises.colors.underline};
    outline: none;
    max-width: 50px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: ${props => props.theme.text.colors.primary};
    margin: 0 2px;
  }
`;

const StyledWordsBank = styled(WordsBank)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    background: ${props => props.theme.exercises.colors.primary};
    border-radius: 6px;
    margin: 8px 8px 0 0;
    padding: 16px;
    @media (max-width: 768px) {
      padding: 12px;
    }
    cursor: pointer;
  }

  .separator {
    border-bottom: 1px solid #CED1DB;
    margin: 8px 0 0 0;
  }

  .wordsbox {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    min-height: 58px;
  }
`;

export {
    StyledChooseTheAnswer as ChooseTheAnswer,
    StyledFillTheGaps as FillTheGaps,
    StyledWordsBank as WordsBank
};
