import styled from "styled-components";

import Player from "./Player";
import Header from "./Header";
import Loader from "./Loader";
import Exercise from "./Exercise";
import ExerciseFeedback from "./ExerciseFeedback";
import ExercisesProgressBar from "./ExercisesProgressBar";
import MediaAsset from "./MediaAsset";

const StyledHeader = styled(Header)`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
    margin: 0;
  }

  p {
    margin: 0;
    font-weight: 600;
    line-height: 15px;
    color: #2b2f6e;
  }

  svg {
    transform: scale(0.8);
    margin-left: -8px;
    margin-bottom: 6px;
    padding-bottom: 1px;
  }
`;

const StyledPlayer = styled(Player)`
  max-width: 720px;
  margin: 0 auto;
`;

const StyledExercise = styled(Exercise)`
  margin-top: 24px;
  @media (max-width: 768px) {
    padding: 0 16px;
    margin-top: 12px;
  }

  .subtitle {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props) => props.theme.text.colors.terciary};
    margin-bottom: 4px;
  }

  .retrySubtitle {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props) => props.theme.feedback.colors.fail};
    margin-right: 4px;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: ${(props) => props.theme.text.colors.primary};
    margin: 0 0 16px 0;
  }

  button {
    background-color: ${(props) => props.theme.button.colors.primary};
    color: ${(props) => props.theme.button.colors.secondary};
    width: 100%;
    max-width: 720px;
    padding: 10px 16px 11px;
    border: none;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    cursor: pointer;
    margin-top: 24px;
    &:hover {
      transition: ${(props) => props.theme.button.animation.hover.transition};
      background-color: ${(props) => props.theme.button.colors.primaryHover};
    }
    &:disabled {
      opacity: ${(props) => props.theme.button.state.disabled.opacity};
    }

    &.continueButton {
      background-color: ${(props) => props.theme.button.colors.secondary};
      color: ${(props) => props.theme.text.colors.primary};
      @media (max-width: 768px) {
        width: 90vw;
      }
      &:hover {
        transition: ${(props) => props.theme.button.animation.hover.transition};
        background-color: ${(props) =>
          props.theme.button.colors.secondaryHover};
      }
    }
  }
`;

const StyledExerciseFeedback = styled(ExerciseFeedback)`
  background: ${(props) =>
    props.success
      ? props.theme.feedback.colors.success
      : props.theme.feedback.colors.fail};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
  position: fixed;
  width: 100vw;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 48px 0;

  p {
    width: 100%;
    max-width: 720px;
    @media (max-width: 768px) {
      width: 90vw;
    }
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.feedback.colors.title};
  }

  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

const StyledExercisesProgressBar = styled(ExercisesProgressBar)`
  display: flex;
  width: 100%;

  div {
    display: inline-block;
  }

  .progressBar {
    background: ${(props) => props.theme.progressBar.colors.primary};
    height: 8px;
    width: ${(props) => `${props.completedProgress}%`};
  }

  .rest {
    background: ${(props) => props.theme.progressBar.colors.terciary};
    height: 8px;
    width: ${(props) => `${100 - props.completedProgress}%`};
  }
`;

const StyledMediasset = styled(MediaAsset)``;

const StyledLoader = styled(Loader)`
  margin: 100px auto;
  width: 64px;
  height: 64px;
  position: relative;

  .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #353e59;
      border-radius: 100%;
      animation: circleFadeDelay 0.9s infinite ease-in-out both;
    }
  }
  .circle2 {
    transform: rotate(40deg);
    &:before {
      animation-delay: -0.8s;
    }
  }
  .circle3 {
    transform: rotate(80deg);
    &:before {
      animation-delay: -0.7s;
    }
  }
  .circle4 {
    transform: rotate(120deg);
    &:before {
      animation-delay: -0.6s;
    }
  }
  .circle5 {
    transform: rotate(160deg);
    &:before {
      animation-delay: -0.5s;
    }
  }
  .circle6 {
    transform: rotate(200deg);
    &:before {
      animation-delay: -0.4s;
    }
  }
  .circle7 {
    transform: rotate(240deg);
    &:before {
      animation-delay: -0.3s;
    }
  }
  .circle8 {
    transform: rotate(280deg);
    &:before {
      animation-delay: -0.2s;
    }
  }
  .circle9 {
    transform: rotate(320deg);
    &:before {
      animation-delay: -0.1s;
    }
  }
  @keyframes circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
`;

export {
  StyledPlayer as Player,
  StyledExercise as Exercise,
  StyledExerciseFeedback as ExerciseFeedback,
  StyledExercisesProgressBar as ExercisesProgressBar,
  StyledMediasset as MediaAsset,
  StyledHeader as Header,
  StyledLoader as Loader,
};
