const getURLQueryVariable = (variableName) => {
  const query = window.location.search.substring(1);
  const paramsAsString = query.split("&");
  const paramsAsObjects = paramsAsString.map((param) => {
    const paramPair = param.split('=');
    return {[paramPair[0]]: paramPair[1]};
  })
  const params = Object.assign({}, ...paramsAsObjects);

  return params[variableName];
};

const getHostFromUrl = () => window?.location?.host;

const urlCommonFunctions = { getURLQueryVariable, getHostFromUrl };
export default urlCommonFunctions;
