import React, { useState } from 'react';

const WordsBox = ({ options, onChooseWord }) => {
  const handleChooseWord = (index) => (event) => {
    const word = event.target.attributes.word.value;
    onChooseWord({ word, index });
  };

  return <div className="wordsbox">
    {options.map((option, index) =>
      <span
        key={`${option}-${index}`}
        word={option}
        onClick={handleChooseWord(index)}
      >
        {option}
      </span>
    )}
  </div>;
};

const WordsBank = ({ className, exercise, setAnswer }) => {
  const [availableWords, setAvailableWords] = useState(exercise.answers_options);
  const [chosenWords, setChosenWords] = useState([]);

  const handleChooseWordToAdd = ({ word, index }) => {
    setAnswer([...chosenWords, word]);

    setChosenWords([...chosenWords, word]);
    const remainingAvailableWords = [...availableWords];
    remainingAvailableWords.splice(index, 1);
    setAvailableWords(remainingAvailableWords);
  };

  const handleChooseWordToRemove = ({ word, index }) => {
    const remainingChosenWords = [...chosenWords];
    remainingChosenWords.splice(index, 1);
    setChosenWords(remainingChosenWords);

    setAvailableWords([...availableWords, word]);

    setAnswer(remainingChosenWords);
  };

  return <div className={className}>
    <WordsBox
      options={chosenWords}
      onChooseWord={handleChooseWordToRemove}
    />
    <div className="separator"></div>
    <WordsBox
      options={availableWords}
      onChooseWord={handleChooseWordToAdd}
    />
  </div>;
};

export default WordsBank;
