import "./App.css";

import { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import { useInitApp } from 'state/root';
import { selectors as uiSelectors } from './state/ui';

import Theme from "components/Theme";
import Player from 'components/Player';
import Feedback from 'components/Feedback';

import PrivateRoute from 'components/Auth/PrivateRoute';
import { ForbiddenPage, SplashPage } from 'pages';

const App = () => {
  let history = useHistory();
  const isAppLoading = useSelector(uiSelectors.isLoading);
  const { initApp } = useInitApp({ history });

  useEffect(() => initApp(), [initApp]);

  return (
    <div className="App">
      {isAppLoading
        ? <SplashPage />
        : <Theme>
            <Switch>
              <PrivateRoute path="/exercises" exact>
                <Player />
              </PrivateRoute>
              <PrivateRoute path="/feedback" exact>
                <Feedback />
              </PrivateRoute>
              <Route path="*">
                <ForbiddenPage />
              </Route>
            </Switch>
          </Theme>
      }
    </div>
  );
};

export default App;
