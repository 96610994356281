import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { selectors as playerSelectors, actions as playerActions } from 'state/player';
import { GET_TASK_LOGS_BY_BLOCK_ID } from 'state/player/api/logs';

const useGetExerciseById = (exerciseId) => {
  const dispatch = useDispatch();
  const exercise = useSelector(playerSelectors.getExerciseById(exerciseId));

  const getExercise = useCallback( () => {
    dispatch(playerActions.getExercise({ exerciseId }));
  }, [dispatch, exerciseId]);

  return {
    exercise,
    getExercise
  };
};

const useGetTaskLogsByBlockId = ({ blockId = null } = {}) => {
  const [
    getTaskLogsById,
    { loading, data: { task_logs: logsList = [] } = {}, error },
  ] = useLazyQuery(GET_TASK_LOGS_BY_BLOCK_ID, {
    variables: {
      blockId,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (blockId) getTaskLogsById()
  }, [blockId, getTaskLogsById])

  return { logsList, loading, error }
}

const hooks = {
  useGetExerciseById,
  useGetTaskLogsByBlockId,
};

export default hooks;
