import { extractDataFromSessionToken } from './sessionTokenExtractor';
import { CLIENT_NAME } from './constants';
import themes from './themes';
import sendStats from './activityReporting';

const astexClient = {
  session: { extractDataFromSessionToken },
  constants: { clientName: CLIENT_NAME },
  themes,
  activity: { sendStats }
};

export default astexClient;
