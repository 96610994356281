import styled from 'styled-components';
import ForbiddenPage from './ForbiddenPage';
import SplashPage from './SplashPage';

const StyledSplashPage = styled(SplashPage)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    svg {
        transform: scale(1.3)
    }
`;

const StyledForbiddenPage = styled(ForbiddenPage)`
    display: flex;
    padding: 0 24px 0 0;

    svg {
        max-height: 800px;
        padding-right: 16px;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 96px;
        line-height: 120px;
        color: ${props => props.theme.text.colors.primary};
        margin: 0;
    }

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 70px;
        color: ${props => props.theme.text.colors.primary};
        margin: 0;
    }

    button {
        background-color: ${props => props.theme.button.colors.primary};
        color: ${props => props.theme.button.colors.secondary};
        width: 100%;
        max-width: 720px;
        padding: 10px 16px 11px;
        border: none;
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        cursor: pointer;
        margin-top: 24px;
        &:hover {
            transition: ${props => props.theme.button.animation.hover.transition};
            background-color: ${props => props.theme.button.colors.primaryHover};
        }
        &:disabled {
            opacity: ${props => props.theme.button.state.disabled.opacity};
        }
    }
`;

export {
    StyledForbiddenPage as ForbiddenPage,
    StyledSplashPage as SplashPage
}
