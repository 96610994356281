import { handleActions } from 'redux-actions';

import actions from '../actionCreators';
import initialState from '../initial.state';

const data = handleActions(
    {
        [actions.setClient]: (state, { payload: { client } }) => ({
            ...state,
            client,
        }),
        [actions.setTheme]: (state, { payload: { theme } }) => ({
            ...state,
            theme,
        }),
        [actions.setBackUrl]: (state, { payload: { backUrl } }) => ({
            ...state,
            back_url: backUrl,
        }),
        [actions.setWebhooks]: (state, { payload: { webhooks } }) => ({
            ...state,
            webhooks,
        }),
    },
    initialState.data || {},
);

export default data;
