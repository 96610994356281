import Auth from "i18n/en/Auth";
import Billing from "i18n/en/Billing";
import Date from "i18n/en/Date";
import Errors from "i18n/en/Errors";
import InvitationsPage from "i18n/en/InvitationsPage";
import Invites from "i18n/en/Invites";
import Navigation from "i18n/en/Navigation";
import Pagination from "i18n/en/Pagination";
import Users from "i18n/en/Users";
import Exercises from "i18n/en/Exercises"

const lang = {
  Auth,
  Billing,
  Date,
  Errors,
  Exercises,
  InvitationsPage,
  Invites,
  Navigation,
  Pagination,
  Users,
};
export default lang;
