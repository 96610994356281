import { useDispatch } from "react-redux";
import { useCallback } from 'react';

import { actions as appActions } from 'state/app';

export const useInitApp = ({ history }) => {
  const dispatch = useDispatch();

  const initApp = useCallback(() => {
    dispatch(appActions.init({ history }));
  }, [dispatch, history]);

  return { initApp };
};
