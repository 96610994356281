import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as playerSelectors, hooks as playerHooks } from 'state/player';
import { actions as appActions } from 'state/app';

import { ExercisesProgressBar, Loader } from 'components/Player/styled';

import {ReactComponent as QR} from './qr.svg';
import {ReactComponent as AppleStore} from './appleStore.svg';
import {ReactComponent as GooglePlay} from './googlePlay.svg';
import {ReactComponent as Demo} from './demo.svg';

const Feedback = ({ className }) => {
  const dispatch = useDispatch();
  const blockId = useSelector(playerSelectors.getBlockId);
  const {
    logsList,
    loading,
  } = playerHooks.useGetTaskLogsByBlockId({ blockId });


  // @TODO: remove magic numbers
  const getAnswerStats = useCallback(() => {
    const numberOfCorrectAnswersFirstTry = (logsList || []).filter((log) => log.execution_type === 'FIRST').length;
    const numberOfCorrectAnswersSecondTry = (logsList || []).filter((log) => log.execution_type === 'SECOND_TRY').length;
    const numberOfInCorrectAnswers = 6 - numberOfCorrectAnswersFirstTry - numberOfCorrectAnswersSecondTry;
    const totalExperiencePoints = 6 * 6;
    const correctAnswersFirstTryExperiencePoints = (logsList || [])
      .filter((log) => log.execution_type === 'FIRST')
      .reduce((points, log) => points + log.execution_exp.points, 0);

    const correctAnswersSecondTryExperiencePoints = (logsList || [])
      .filter((log) => log.execution_type === 'SECOND_TRY')
      .reduce((points, log) => points + log.execution_exp.points, 0);

    const percentageExperiencePoints = Math.ceil(((correctAnswersFirstTryExperiencePoints + correctAnswersSecondTryExperiencePoints) / totalExperiencePoints) * 100 );
    const totalTimeSpent = (logsList || []).reduce((time, log) => time + log.time_spent_min, 0);

      return {
        numberOfCorrectAnswersFirstTry,
        numberOfCorrectAnswersSecondTry,
        numberOfInCorrectAnswers,
        totalExperiencePoints,
        correctAnswersFirstTryExperiencePoints,
        correctAnswersSecondTryExperiencePoints,
        percentageExperiencePoints,
        totalTimeSpent
      };

  }, [logsList]);

  const finishExercisesBlock = useCallback(() => {
    const stats = getAnswerStats();
    dispatch(appActions.finish({
      stats: {
        number_of_exercises: 6,
        correct_on_first_try: stats.numberOfCorrectAnswersFirstTry,
        correct_on_retry: stats.numberOfCorrectAnswersSecondTry,
        incorrect: stats.numberOfInCorrectAnswers,
        time_spent: stats.totalTimeSpent
      }
    }));
  }, [getAnswerStats, dispatch]);


  const answerStats = getAnswerStats();
  return <div className={className}>
    {loading
      ? <Loader />
      : <div>
          <div className="header">
            <p className="title">Stage completed!</p>
            <p className="subtitle">
              {`${answerStats.numberOfCorrectAnswersFirstTry + answerStats.numberOfCorrectAnswersSecondTry} out of 6, `}
              { answerStats.numberOfCorrectAnswersFirstTry + answerStats.numberOfCorrectAnswersSecondTry >= 3
                ? 'you are on a roll!'
                : 'better luck next time!'
              }
            </p>
          </div>
          <div className="content">
            {(answerStats.numberOfCorrectAnswersFirstTry > 0) && <p className="result">
                <span>{answerStats.numberOfCorrectAnswersFirstTry} correct answers</span>
                <span className="experience">{answerStats.correctAnswersFirstTryExperiencePoints} exp</span>
              </p>
            }
            {(answerStats.numberOfCorrectAnswersSecondTry > 0) && <p className="result">
                <span>{answerStats.numberOfCorrectAnswersSecondTry} correct answers on retry</span>
                <span className="experience">{answerStats.correctAnswersSecondTryExperiencePoints} exp</span>
              </p>
            }
            <p className="experienceBarTitle">
              <span>Total</span>
              <span>{`${answerStats.correctAnswersFirstTryExperiencePoints + answerStats.correctAnswersSecondTryExperiencePoints} / ${answerStats.totalExperiencePoints} exp`}</span>
            </p>
            <ExercisesProgressBar completedProgress={answerStats.percentageExperiencePoints} className="experienceBar"/>
            <button onClick={finishExercisesBlock}>Finish</button>
          </div>
          <div className="footer">
            <QR className="qr"/>
            <div className="text">
              <p className="title">Complete the experience in the app!</p>
              <p className="subtitle">Download Wannalisn to your mobile by scanning this QR code.</p>
              <div className="links">
                <a href="https://google.com/">
                  <AppleStore />
                </a>
                <a href="https://google.com/">
                  <GooglePlay />
                </a>
              </div>
            </div>
            <Demo className="demo"/>
          </div>
        </div>
    }
  </div>
};

export default Feedback;
