import { handleActions } from 'redux-actions';

import actions from '../actionCreators';
import initialState from '../initial.state';

const ui = handleActions(
    {
        [actions.setLoading]: (state, { payload: { loading } }) => ({
            ...state,
            loading,
        }),
    },
    initialState.ui || {},
);

export default ui;