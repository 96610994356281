const SET_TOKEN = 'auth/SET_TOKEN';
const SET_USER = 'auth/SET_USER';
const SET_LOGGED_IN = 'auth/SET_LOGGED_IN';

const authActionTypes = {
  SET_TOKEN,
  SET_USER,
  SET_LOGGED_IN,
};

export default authActionTypes;
