import styled from 'styled-components';

import Feedback from './Feedback';

const StyledFeedback = styled(Feedback)`
    display: flex;
    flex-direction: column;
    height: 100%;

    .experienceBarTitle {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        justify-content: space-between;
        span {
            font-weight: bold;
        }
    }

    .experienceBar {
        .progressBar {
            background: ${props => props.theme.progressBar.colors.secondary};
        }
    }

    .header {
        background: ${props => props.theme.feedback.colors.background};
        display: flex;
        flex-direction: column;
        height: 176px;
        padding-left: 200px;
        padding-bottom: 24px;
        justify-content: flex-end;
        @media (max-width: 768px) {
            padding: 16px;
            height: auto;
        }

    }

    .content {
        max-width: 720px;
        margin: 48px auto 0 auto;
        @media (max-width: 768px) {
            margin: 16px;
        }
    }

    .footer {
        position: fixed;
        width: 100%;
        bottom: 0px;
        height: 300px;
        background: ${props => props.theme.feedback.colors.background};
        display: flex;
        flex-direction: row;
        align-items: center;
        .qr {
            flex: 1;
        }

        .text {
            flex: 2;
            @media (max-width: 768px) {
                flex: none;
                padding: 16px 0;
            }
        }

        a {
            margin: 16px 8px 0 0 ;
        }

        p {
            margin-bottom: 16px;
        }

        @media (max-width: 768px) {
            height: auto;
            flex-direction: column;
            .subtitle {
                display: none;
            }

            .qr {
                display: none;
            }
        }
    }

    svg.demo  {
        flex: 1;
        @media (max-width: 768px) {
            display: none;
        }
    }

    p {
        margin: 4px 0;
    }

    button {
        background-color: ${props => props.theme.button.colors.primary};
        color: ${props => props.theme.button.colors.secondary};
        width: 100%;
        max-width: 720px;
        padding: 10px 16px 11px;
        border: none;
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        cursor: pointer;
        margin-top: 24px;
        &:hover {
            transition: ${props => props.theme.button.animation.hover.transition};
            background-color: ${props => props.theme.button.colors.primaryHover};
        }
        &:disabled {
            opacity: ${props => props.theme.button.state.disabled.opacity};
        }
    }

    .links {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    p.title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        color: ${props => props.theme.text.colors.secondary};
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 0;
        }
    }

    p.subtitle {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: ${props => props.theme.text.colors.secondary};
    }

    p.result {
        background: #EDF2FF;
        border-radius: 6px;
        padding: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #353E59;
        margin-bottom: 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        span.experience {
            font-weight: bold;
        }
    }
`;

export { StyledFeedback as Feedback };
