import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import defaultTheme from './defaultTheme';
import { selectors as sessionSelectors } from 'state/session';

const Theme = ({ children }) => {
    const theme = useSelector(sessionSelectors.getTheme);
    return <ThemeProvider theme={{...defaultTheme, ...theme}}>
        {children}
    </ThemeProvider>
  };

export default Theme;
