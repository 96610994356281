const initialState = {
  ui: {
    loading: false,
  },
  data: {
    blockId: null,
    exercisesQuery: {},
    exercisesIds: [],
    exercisesById: {}
  },
};

export default initialState;
