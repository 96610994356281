import React, { useEffect, useRef } from 'react';
import { commonUtils } from 'utils';

const isAGap = (option) => option === '-';

const FillTheGaps = ({ className, exercise, setAnswer }) => {

  const inputsRef = useRef([]);
  const answerOptions = exercise.answers_options[0].split(' ');

  const handleSetAnswer = (_) => {
    const answer = inputsRef.current.filter((input) => !commonUtils.isEmpty(input)).map((input) => input.value);
    setAnswer(answer);
  };

  useEffect(
    () => inputsRef.current.filter((input) => !!input)[0].focus(),
    [inputsRef]
  );

  return <div className={className}>
    {answerOptions.map((option, index) => {
      if (isAGap(option)) {
        return <div className="wrapper" key={`${exercise.id}-${index}-${option}`}>
          <input
            type="text"
            ref={el => inputsRef.current[index] = el}
            onChange={handleSetAnswer}
            defaultValue={null}
          />
        </div>
      }

      return <span key={`${index}-${option}`}>{option}</span>
    })}
  </div>;
};

export default FillTheGaps;
