import React, { useEffect, useRef, useMemo } from 'react';

import config from 'config';

const Video = ({ className, asset }) => {
  const videoRef = useRef(null);

  const playVideo = async () => {
    const myVideo = videoRef.current;
    try {
      await myVideo.play();
    } catch (error) {
      console.error(error);
    }
  };

  const assetUrl = useMemo(() => {
    const videoURLToLoad = asset.url_full;
    const url = `${config.VIDEOS_BASE_URL}${videoURLToLoad}`.replace(' ', '%20');

    return url;
  }, [asset]);

  useEffect(() => playVideo(), []);

  return <div className={className}>
    <video
      ref={videoRef}
      autoPlay
      controls
      playsInline
      src={assetUrl}
      type="video/mp4"
      controlsList="nofullscreen nodownload"
    >
      Your browser does not support video tag.
    </video>
  </div>;
};

export default Video;
