import { createActions } from 'redux-actions';

import types from './actionTypes';

const { session } = createActions({
  [types.SET_CLIENT]: ({ client }) => ({ client }),
  [types.SET_THEME]: ({ theme }) => ({ theme }),
  [types.SET_BACK_URL]: ({ backUrl }) => ({ backUrl }),
  [types.SET_WEBHOOKS]: ({ webhooks }) => ({ webhooks }),
});

export default session;
