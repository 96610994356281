import { gql } from 'apollo-boost';
import { client } from 'apollo/client';

const GET_TASK_LOGS_BY_BLOCK_ID = gql`
query getTaskLogsById($blockId: String!) {
  task_logs(
    where: {
      block_id: { _eq: $blockId }
    }
    order_by: { exercise_id: desc, created_at: desc }
  ) {
    exercise_id
    errors
    created_at_date
    id
    language
    passed
    score
    sum_points
    type
    clues
    block_id
    aws_id
    execution_type
    time_spent_min
    execution_exp {
      points
      title
    }
  }
}
`;

const logsApi = {
  getLogsByBlockId: async ({ blockId }) => {
    const { data } = await client.query({
      query: GET_TASK_LOGS_BY_BLOCK_ID,
      variables: { blockId },
      fetchPolicy: 'no-cache',
    });

    return data?.task_logs || null;
  },
};

export { GET_TASK_LOGS_BY_BLOCK_ID };
export default logsApi;
