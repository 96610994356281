import styled from 'styled-components';

import Video from './Video';

const StyledVideo = styled(Video)`
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  max-width: 720px;
  @media (max-width: 768px) {
    margin-top: 0;
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6pt;
    @media (max-width: 768px) {
      border-radius: 0;
    }
  }
`;

export {
    StyledVideo as Video
}
