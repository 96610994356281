import { Auth } from "infra";
import config from "config";
import { urlUtils } from "utils";

const CONTENT_TYPES = {
    json: 'application/json',
};

const BASE_URL = urlUtils.getHostFromUrl().split('.').includes('astex')
    ? config.ASTEX_BASE_URL
    : config.BASE_URL;

const getToken = async () => {
    try {
        const { idToken = {} } = await Auth.currentSession();
        return idToken?.jwtToken;
    } catch (error) {
        console.error('ERROR RETRIEVING AUTH TOKEN', error);
        throw error;
    };
};

const authFetch = async ({
    method,
    body,
    path,
    uri = false,
    contentType = CONTENT_TYPES.json
}) => {
    const jwtToken = await getToken();
    const url = uri ? uri : `${BASE_URL}/${path}`;

    return await fetch(url, {
        method,
        mode: 'cors',
        ...(body ? { body: JSON.stringify(body) } : {}),
        headers: {
            'Content-Type': contentType,
            'Authorization': jwtToken ? `Bearer ${jwtToken}` : '',
        }
    });
};

const generateLoginCredentials = async ({
    username,
    client,
    token
}) =>
    await fetch(`${BASE_URL}/provider/auth`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ username, client, token }),
        headers: {
            'Content-Type': CONTENT_TYPES.json,
        }
    });

const post = async ({ ...params }) => await authFetch({ method: 'POST', ...params });

const api = () => ({ post, generateLoginCredentials });
export default api;
